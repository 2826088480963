import React, { useEffect } from "react";
import NavBar from "../../../components/navbar/navbar.component";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { trackOrder } from "../../../app/features/Customer/Order/trackingOrderSlice";
import { getOrderId } from "../../../app/features/Customer/Order/getOrderSlice";
import logo from "../../../assets/images/rse-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "./orders.styled";
import Barcode from "react-barcode";
import "./track.customer.css";

const ScheduledWaybill = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const dollarUSLocale = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const [data, setData] = React.useState({});

  useEffect(() => {
    dispatch(trackOrder(id)).then((res) => {
      const { error, payload } = res;
      if (!error) {
        setData({shipment :payload});
      }
    });
  }, []);
  const officeCopy =(no) => {
    dispatch(getOrderId(no)).then((res) => {
      const { error, payload } = res;
      if (!error) {
        console.log(payload)
        navigate(
          `/dashboard/customer/waybill/${payload}`
        )
      }
    });
  }
  const paymentDefinition = (type) => {
    switch (type) {
      case 0:
        return "Prepaid";
      case 1:
        return "POD";
      default:
        return "Prepaid";
    }
  };

  return (
    <>
    <NavBar />
    <div className="main-content main-content-margin-top">
    
      <Container className="container">
        {data.shipment != null &&
          <div className="row mb-3" v-if="data.shipment">
          <div className="align-items-center">
            <div className="row">
              <div className="col-md-10">
            <h3 className="page-title fw-bold">{ data.shipment.waybillNumber }</h3>
            <p className="page-subl-title fw-bold">
              { data.shipment.createdOn ? new Date(data.shipment.createdOn).toLocaleDateString() : null }
            </p>
            </div>
            {data.shipment != null && <div className="col-md-2">
      <a className="btn btn-primary btn-sm" onClick={() => officeCopy(data.shipment.waybillNumber)}>Print Waybill</a>
    </div>}
            </div>
            <div className="row">
              <div className="d-flex col-12 col-sm-6 col-md-5 col-lg-4 mb-2">
                <span className="page-subl-title">
                  Origin Hub:
                </span>
                <span className="page-subl-title ms-auto fw-bold">
                  { data.shipment.origin?.name };
                </span>
              </div>
              <div className="d-flex col-12 col-sm-6 col-md-5 col-lg-4 mb-2">
                <span className="page-subl-title">
                  Destination Hub:
                </span>
                <span className="page-subl-title ms-auto fw-bold">
                  { data.shipment.town && data.shipment.city ? data.shipment.town?.name + ', ' + data.shipment.city?.name : data.shipment.city?.name }
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="card detail-card mb-3">
              <div className="card-header">
                <p className="head-title mb-0">Recipient Information</p>
              </div>
              <div className="card-body">
                <div className="">
                  <div className="row mb-2">
                    <div className="col-12 col-sm-4 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Name</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.recipient.recipientName} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Email</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.recipient.email} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Telephone</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.recipient.phoneNumber} />
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Street Address</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.recipient.streetAddress} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2" v-if="data.shipment.recipient.city || data.shipment.recipient.city != null">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">City</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.recipient.city} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2" v-if="data.shipment.recipient.state">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">State</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.recipient.state} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2" v-if="data.shipment.recipient.country">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Country</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.recipient.country} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2" v-if="data.shipment.recipient.postalCode">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Postal/ZIP Code</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.recipient.postalCode} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card detail-card mb-3">
              <div className="card-header">
                <p className="head-title mb-0">Packaging</p>
              </div>
              <div className="card-body">
                <div className="">
                  <div className="row mb-2">
                    <div className="col-6 col-sm-3">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Packaging</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.packaging} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">data.shipment Type</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.type} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Delivery Type</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.deliveryType} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Flyer No</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.flyerNo} />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2" v-if="data.shipment.packaging === 'Dimensional Box'">
                    <div className="col-4">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Length</label>
                        <input type="number" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.length} />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Width</label>
                        <input type="number" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.width} />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Height</label>
                        <input type="number" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.height} />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12 col-sm-4 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Item Quantity</label>
                        <input type="number" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.unit} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Weight</label>
                        <input type="number" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.weight} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Crating</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.boxandCrating} />
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Content Description</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.contentDescription} />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Content Description</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.contentDescription} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-header">
                <p className="head-title mb-0">Payment Information</p>
              </div>
              <div className="card-body">
                <div className="">
                  <div className="row mb-2">
                    <div className="col-6 col-sm-3 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Service Price</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.shipmentPayment.servicePrice} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Onforwarding</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.shipmentPayment.onforwardingCharge} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Insurance</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.shipmentPayment.insuranceValue} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Crating Charge</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.shipmentPayment.cratingValue} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Additional Charge</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.shipmentPayment.additionalCharge} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Discount</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.shipmentPayment.discount} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">VAT</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.shipmentPayment.vat} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Declared Value</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.shipmentPayment.declaredValue} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Total Amount</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.shipmentPayment.totalAmount} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Amount Paid</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.shipmentPayment.paidAmount} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Payment Method</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.shipmentPayment.paymentMethod} />
                      </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-2" v-if="data.shipment.data.shipmentPayment?.paymentMethod === 'Bank Transfer'">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Bank</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.shipmentPayment.bankAccount} />
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            {data.shipment.exception &&
            <div className="card detail-card mb-3">
              <div className="card-body text-center">
                <p className="exception mb-0">
                  { data.shipment.exception }
                </p>
              </div>
            </div> }
            {data.shipment.pod &&
            <div className="card detail-card mb-3">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-6 col-sm-4 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Delivery Date</label>
                        <p> {data.shipment.pod.deliveredDate ? data.shipment.pod.deliveredDate.toLocaleString() : "" }</p>
                      </div>
                  </div>
                  <div className="col-6 col-sm-4 mb-2">
                      <div className="form-label-group in-border">
                        <label for="company" className="px-0 form-label action-label">Receiver Name</label>
                        <input type="text" id="company" disabled className="form-control action-input form-control-lg shadow-none" value={data.shipment.pod.receiverName} />
                      </div>
                  </div>
                  <div className="col-6 col-sm-4 mb-2">
                    <img src={'data:image/jpeg;base64, ' + data.shipment.pod.receiverSign} className="mb-2" height="70" alt="Receiver Sign" />
                  </div>
                </div>
              </div>
            </div> }
            <div className="card detail-card mb-3">
              <div className="card-header">
                <p className="head-title mb-0">data.shipment Status</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-sm-12">
                  <div
                      className="status-section d-flex align-items-center"
                      style={{ marginTop: "16px" }}
                      data-content
                    >
                      {data.shipment.pStatus?.map((s, key) => {
                        return (
                          <div
                            className={
                              s.value
                                ? "order-tracking completed"
                                : "order-tracking"
                            }
                          >
                            {/* <div className=""> */}
                            {s.value ? (
                              <span className="completed"></span>
                            ) : (
                              <span className="is-complete"></span>
                            )}
                            {/* </div> */}
                            <div className="text" style={{ marginTop: "16px" }}>
                              <h3
                                className="status-title mb-0"
                                style={{
                                  fontSize: ".943rem",
                                  fontWeight: "500",
                                }}
                              >
                                {s.name}
                              </h3>
                              {s.value && (
                                <span
                                  className="status-title mb-0"
                                  style={{ fontSize: ".743rem" }}
                                >
                                  {s.location}
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
       }
        
      </Container>
      </div>
    </>
  );
};

export default ScheduledWaybill;
