import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { Flex } from "../../../components/Table/table.styled";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import PlaceIcon from "@mui/icons-material/Place";
import NavBar from "../../../components/navbar/navbar.component";
import { Container } from "./orders.styled";
import { Heading } from "../../../components/Tags/tags.styled";
import { Close } from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toast } from "react-toastify";
import { userObj } from "../../../helper/protectedRoutes";
import { exportMerchantReport } from "../../../app/features/Customer/Order/bulkOrderTemplateSlice";
import { PaystackButton } from "react-paystack";

const MerchantReportPage = () => {
  const dispatch = useDispatch();
  const user = userObj?.extra;
  console.log("user >>", user);

  const navigate = useNavigate();
  const types = [
    {label: 'Wallet Transactions', value: 5},
    {label: 'Delivery Shipment Report', value: 1},
    {label: 'Picked-up Shipment Report', value: 6},
    {label: 'Returned Shipment Report', value: 3},
    {label: 'Shipment with issues Report', value: 2},
    {label: 'Settlement Summary Report', value: 4}
  ];

  const formats = ["PDF", "Excel", "Word"]

  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [type, setType] = React.useState(1);
  const [format, setFormat] = React.useState(null);

  const isDownloading = useSelector((state) => state.downloading);

  const handleStartDateChange = (e) => {
    const { value } = e.target;
    setStartDate(value);
  };
  const handleEndDateChange = (e) => {
    const { value } = e.target;
    setEndDate(value);
  };
  const handleTypeChange = (e) => {
    const { value } = e.target;
    setType(value);
  };
  const handleFormatChange = (e) => {
    const { value } = e.target;
    setFormat(value);
  };

  const handleDownload = () => {
    var r = {
      startDate, endDate, type, format
    }
    dispatch(exportMerchantReport(r)).then( res => {
      const { payload, error } = res;
      if (!error) {
        console.log(payload)
        let url = window.URL.createObjectURL(payload.data);
        let a = document.createElement('a');
        a.href = url;
        var d =payload.headers['content-disposition']
        var e = d.split("; ");
        var s = e[e.length-1]
        console.log(s)
        a.download = s.replace("filename*=UTF-8''", "")
        a.click();
      } else {
        toast(error)
      }
    })
  }

  
  return (
    <>
      <NavBar />
      <div className="main-content main-content-margin-top">
        <Container>
          <Heading size="1.8rem">Merchant Report</Heading>
          <Card
            sx={{
              marginBottom: "12px",
            }}
            variant="outlined"
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div className="">
                  <FormControl sx={{ m: 1, width: "50ch" }} size="small">
                  <TextField
                    sx={{ m: 1, width: "50ch" }}
                    label="Start Date"
                    id="outlined-size-small"
                    name="startDate"
                    value={startDate}
                    type="date"
                    size="small"
                    onChange={(e) => handleStartDateChange(e)}
                  />
                  </FormControl>
                  <FormControl sx={{ m: 1, width: "50ch" }} size="small">
                  <TextField
                    sx={{ m: 1, width: "50ch" }}
                    label="End Date"
                    id="outlined-size-small"
                    name="endtDate"
                    value={endDate}
                    type="date"
                    size="small"
                    onChange={(e) => handleEndDateChange(e)}
                  />
                  </FormControl>
                  <FormControl sx={{ m: 1, width: "50ch" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      Report Type
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="type"
                      value={type}
                      label="Type"
                      onChange={(e) => handleTypeChange(e)}
                    >
                      <MenuItem value={null}>
                        <em>-- Select --</em>
                      </MenuItem>
                      {types?.map((t, key) => (
                        <MenuItem value={t.value} key={key}>
                          {t.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: "50ch" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      Format
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="format"
                      value={format}
                      label="Format"
                      onChange={(e) => handleFormatChange(e)}
                    >
                      <MenuItem value={null}>
                        <em>-- Select --</em>
                      </MenuItem>
                      {formats?.map((t, key) => (
                        <MenuItem value={t} key={key}>
                          {t}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Box>
              <Box
            sx={{
              marginTop: "12px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {isDownloading ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="primary"
                >
                  <CircularProgress />
                </Button>
              ) : (
            <Button
              style={{ textTransform: "capitalize", fontWeight: "700" }}
              variant="contained"
              color="primary"
              onClick={() => handleDownload()}
            >
              Download
            </Button>)}
          </Box>
            </CardContent>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default MerchantReportPage;
