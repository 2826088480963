import React from "react";
import Chart from "react-apexcharts";

class ListOfOrdersChart extends React.Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      series: props.series
    };
  }


  render() {
    return (
      <div id="chart">
        <Chart
          options={{
            chart: {
              id: "Monthly Pickups vs Deliveries",
            },
            colors: ["#000000", "grey"],
            xaxis: {
              categories: this.props.months,
            },
            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                columnWidth: "45%",
              },
            },
          }}
          series={this.state.series}
          type="bar"
          height={270}
        />
      </div>
    );
  }
}

export default ListOfOrdersChart;
