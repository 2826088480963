import styled from "styled-components";

export const WidgetContainer = styled.div`
    min-width: 230px;
    background-color: #fff;
    margin-bottom: 1.5rem;
    -webkit-box-shadow: 0 1px 2px #ccc;
    box-shadow: 0 1px 2px #ccc;
    padding: 10px!important;
    margin: 8px;
    box-sizing: border-box;
    border-bottom: 20px solid ${props => props.bottomColor ? props.bottomColor : "none"};

    p{
        margin-bottom:8px;
        margin-top:8px;
    }

    @media screen and (max-width: 768px){
        width: 96%;
        margin: 8px auto;
    }
`