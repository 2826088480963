import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../../components/navbar/navbar.component";
import Widget from "../../../components/widgets/widget.component";
import { WidgetFlex } from "../../Partner-Pages/Dashboard/dashboard.styled";
import { Avatar, Box, CircularProgress, Stack } from "@mui/material";
import ChartWidget from "../../../components/widgets/chart-widget/chart_widget.component";
import { getOrderId, cancelOrder } from "../../../app/features/Customer/Order/getOrderSlice";
import ListOfOrdersChart from "../../../customer-charts/order_list.chart";
import { makeStyles } from "@mui/styles";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import { exportOrderByStatus } from "../../../app/features/Customer/Order/bulkOrderTemplateSlice";
import MuiAlert from "@mui/material/Alert";
import {
  Flex,
  Table,
  TableWrapper,
  VehiclesTableContainer,
} from "../../Partner-Pages/Vehicle/vehicles.styled";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ChartWidgetContainer } from "../../../components/widgets/chart-widget/chart_widget.styled";
import {
  CustomerActionsDiv,
  CustomerActionsDivFlex,
  FlexEnd,
  WalletBalances,
} from "./dashboard.styled";
import { Input, InputContainer, Button as Btn } from "../../Auth/auth.styled";
import { Heading } from "../../../components/Tags/tags.styled";
import GoogMap from "../../../helper/googlemap/map.google";
import { useDispatch, useSelector } from "react-redux";
import { getOrderTops, getSummary } from "../../../app/features/Customer/Order/allOrdersSlice";
import convertDate from "../../../helper/dateCoverter";
import {
  closeOrder,
  openOrder,
} from "../../../app/features/createOrderModal/createordermodalSlice";
import CreateOrderModal from "../../../components/createOrder/createOrderModal.component";
import { getWallet } from "../../../app/features/Customer/wallet/getWalletSlice";
import { toast } from "react-toastify";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
  root: {
    background: "transparent !important",
    marginRight: "auto !important",
    marginLeft: "auto !important",
    padding: "5px",
  },
});

const DashboardCustomer = () => {
  const dollarUSLocale = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [sum, setSum] = React.useState({});
  const [orderdata, setOrderData] = React.useState({});
  const [openAssignDriver, setOpenAssignDriver] = React.useState(false);
  const [openTrackOrderModal, setOpenTrackOrderModal] = React.useState(false);
  const [alertToggle, setAlertToggle] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [assignDriverProperty, setAssignDriverProperty] = React.useState({
    driver: "",
    vehicle: "",
  });

  const [userData, setUserData] = React.useState();
  const [userLocation, setUserLocation] = React.useState(null);
  const [trackNo, setTrackNo] = React.useState("");
  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

  useEffect(() => {
    document.title = "Dashboard - customer";
    dispatch(getSummary()).then((res) => {
      const { payload, error } = res;
      {
        if (!error) {
          setSum(payload);
        }
      }
    });
    dispatch(getOrderTops()).then((res) => {
      const { payload, error } = res;
      {
        if (!error) {
          setOrderData(payload);
        }
      }
    });
    dispatch(getWallet());
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("position", position);
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  }, []);

  const officeCopy =(no) => {
    dispatch(getOrderId(no)).then((res) => {
      const { error, payload } = res;
      if (!error) {
        console.log(payload)
        navigate(
          `/dashboard/customer/waybill/${payload}`
        )
      }
    });
  }

  const _cancelOrder =(no) => {
    var resp = window.confirm("Are you sure you want to cancel this order?")
    if(resp){
    dispatch(getOrderId(no)).then((res) => {
      const { error, payload } = res;
      if (!error) {
        console.log(payload)
       dispatch(cancelOrder(payload)).then(r => {
        const { error, payload } = r;
          if (!error) {
            toast("Order has been cancelled successfully")
            dispatch(getOrderTops()).then((res) => {
              const { payload, error } = res;
              {
                if (!error) {
                  setOrderData(payload);
                }
              }
            });
            dispatch(getWallet());
          }
       })
      }
    });
    }
  }

  const handleDownload = (recent, control) => {
    var r = {
      recent, control
    }
    dispatch(exportOrderByStatus(r)).then( res => {
      const { payload, error } = res;
      if (!error) {
        let url = window.URL.createObjectURL(payload);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${control == 0 ? 'Pending_' : (control == 1) ? 'Awaiting_' : (control == 2) ? 'Delivered_' : (control == 3) ? 'Disputed_' : 'Returned_'}ShipmentFor${recent ? new Date().getFullYear() + '-' + (new Date().getMonth()+1) : 'ALL'}.xlsx`;
        a.click();
      } else {
        toast(error)
      }
    })
  }

  const userObj = JSON.parse(localStorage.getItem("user"));
  const userWalletObj = userObj?.extra.wallet;

  const { isLoading } = useSelector((state) => state.getAllOrders);
  const { wallet } = useSelector((state) => state.getWallet);

  const { isCreateOrderModalOpened } = useSelector(
    (state) => state.createordermodal
  );

  const handleAssignDriverChange = (event) => {
    const { name, value } = event.target;
    setAssignDriverProperty({
      ...assignDriverProperty,
      [name]: value,
    });
  };
  const classes = useStyles();

  //   Book Ride Modal
  // Set Order Location Modal
  const handleOpenOrderLocation = () => {
    dispatch(openOrder());
    handleCloseOrderType();
  };

  const [openOrderType, setOpenOrderType] = React.useState(false);

  const handleOpenOrderType = () => {
    setOpenOrderType(true);
  };
  const handleCloseOrderType = () => {
    setOpenOrderType(false);
  };

  // const handleCloseOrderLocation = () => {
  //   setOrderLocationDetails(false);
  // };

  //  Book ride ends

  const handleTrackOrderModalOpen = () => {
    setOpenTrackOrderModal(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTrackOrderModalClose = () => {
    setOpenTrackOrderModal(false);
  };

  const handleClickOpen = () => {
    setOpenAssignDriver(true);
  };

  const handleClose = () => {
    setOpenAssignDriver(false);
  };

  // Assign Driver
  const AssignDriver = () => {
    handleAlertOpen();
    setOpenAssignDriver(false);
  };

  // Alert Settings
  const handleAlertOpen = () => {
    setAlertToggle(true);
  };

  const handleAlertClose = () => {
    setAlertToggle(false);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setTrackNo(value);
  };

  const handleTrackOrder = (e) => {
    if (trackNo !== "") {
      navigate(`/dashboard/customer/scheduled/${trackNo}`);
    } else {
      toast.warning("Please input the order number to track");
    }
  };

  // console.log(convertDate('2022-09-21T12:56:53.3739294'))

  return (
    <>
      <NavBar />

      {/*------------------- Alert --------------------*/}
      <Snackbar
        open={alertToggle}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfully Assigned Driver to Vehicle
        </Alert>
      </Snackbar>

      {/*  Action Modal */}

      <div name="Assign Driver Modal Parent Node">
        <Dialog open={openAssignDriver} onClose={handleClose}>
          <DialogTitle>Assign Driver</DialogTitle>
          <DialogContent>
            <div>
              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select driver
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={assignDriverProperty.driver}
                  name="driver"
                  label="Select driver"
                  onChange={handleAssignDriverChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"John Doe"}>John Doe</MenuItem>
                  <MenuItem value={"Hidora Kai"}>Hidora Kai</MenuItem>
                  <MenuItem value={"Machala"}>Machala</MenuItem>
                </Select>
                <FormHelperText>select a driver to add</FormHelperText>
              </FormControl>
            </div>
            <div>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select Vehicle
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={assignDriverProperty.vehicle}
                  name="vehicle"
                  label="Select Vehicle"
                  onChange={handleAssignDriverChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Lamborghini"}>Lamborghini</MenuItem>
                  <MenuItem value={"Napep"}>Napep</MenuItem>
                  <MenuItem value={"Truck"}>Mercedez Truck</MenuItem>
                </Select>
                <FormHelperText>
                  choose one vehicle from the list of your vehicle list
                </FormHelperText>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: "capitalize" }}
              color="error"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ textTransform: "capitalize", fontWeight: "700" }}
              variant="contained"
              color="primary"
              onClick={() => AssignDriver()}
            >
              Assign
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div name="Add Vehicle Modal Parent Node">
        <Dialog open={openTrackOrderModal} onClose={handleTrackOrderModalClose}>
          <DialogTitle>Track Order</DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: "capitalize", fontWeight: "700" }}
              variant="contained"
              color="primary"
              onClick={handleTrackOrderModalClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div name="Order Type">
        <Dialog open={openOrderType} onClose={handleCloseOrderType}>
          <Box
            style={{ marginTop: "10px" }}
            sx={{
              maxWidth: "auto",
              display: "flex",
            }}
          >
            <Button style={{}} onClick={handleCloseOrderType} color="error">
              <KeyboardBackspaceIcon />
            </Button>
            <DialogTitle style={{ paddingLeft: "0" }}>
              Select Order Type
            </DialogTitle>
          </Box>
          <DialogContent>
            <Box>
              <Button
                style={{
                  textTransform: "capitalize",
                  fontWeight: "700",
                  marginBottom: "5px",
                }}
                variant="outlined"
                color="error"
                sx={{ width: "100%" }}
                onClick={() => handleOpenOrderLocation()}
              >
                Express (Same day delivery)
              </Button>
              <Button
                style={{
                  textTransform: "capitalize",
                  fontWeight: "700",
                  marginBottom: "5px",
                }}
                variant="outlined"
                color="error"
                sx={{ width: "100%" }}
                onClick={() =>
                  navigate("/dashboard/customer/orders/package-schedule")
                }
              >
                Scheduled Delivery
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </div>

      <CreateOrderModal />

      {/* Quick Acion Modal Ends */}

      <div className="main-content main-content-margin-top">
        {/* Chart Section */}
        <span style={{paddingLeft: '15px'}}><b>TRANSACTION COUNT FOR {month[new Date().getMonth()]?.toUpperCase()}, {new Date().getFullYear()}</b></span>
        <WidgetFlex>
          
          <a onClick={() => handleDownload(true, 0)}>
            <Widget
              headcolor="#10c469"
              head={sum?.transactionCounts ? sum?.transactionCounts[0]?.pending : 0}
              
              bottom="Pending"
            />
          </a>

          <a onClick={() => handleDownload(true, 1)}>
            <Widget
              headcolor="#ff5b5b"
              head={sum?.transactionCounts ? sum?.transactionCounts[0]?.waiting: 0}
              
              bottom="Awaiting"
            />
          </a>

          <a onClick={() => handleDownload(true, 2)}>
            <Widget headcolor="#188ae2" 
            head={sum?.transactionCounts ? sum?.transactionCounts[0]?.delivered: 0}
            bottom="Delivered" />
          </a>

          <a onClick={() => handleDownload(true, 4)}>
            <Widget headcolor="#f9c851" 
            head={sum?.transactionCounts ? sum?.transactionCounts[0]?.exception: 0} bottom="Disputed Packages" />
          </a>

          <a onClick={() => handleDownload(true, 3)}>
            <Widget headcolor="#848fa5" 
            head={sum?.transactionCounts ? sum?.transactionCounts[0]?.returned: 0} bottom="Returned" />
          </a>
        </WidgetFlex>
        <span style={{paddingLeft: '15px'}}><b>TRANSACTION COUNT FOR INCEPTION</b></span>
        <WidgetFlex>
        <a onClick={() => handleDownload(false, 0)}>
            <Widget
              headcolor="#10c469"
              head={sum?.transactionCounts ? sum?.transactionCounts[1]?.pending: 0}
              
              bottom="Pending"
            />
          </a>

          <a onClick={() => handleDownload(false, 1)}>
            <Widget
              headcolor="#ff5b5b"
              head={sum?.transactionCounts ? sum?.transactionCounts[1]?.waiting: 0}
              
              bottom="Awaiting"
            />
          </a>

          <a onClick={() => handleDownload(false, 2)}>
            <Widget headcolor="#188ae2" 
            head={sum?.transactionCounts ? sum?.transactionCounts[1]?.delivered: 0}
            bottom="Delivered" />
          </a>

          <a onClick={() => handleDownload(false, 4)}>
            <Widget headcolor="#f9c851" 
            head={sum?.transactionCounts ? sum?.transactionCounts[1]?.exception: 0} bottom="Disputed Packages" />
          </a>

          <a onClick={() => handleDownload(false, 3)}>
            <Widget headcolor="#848fa5" 
            head={sum?.transactionCounts ? sum?.transactionCounts[1]?.returned: 0} bottom="Returned" />
          </a>
        </WidgetFlex>
        <WidgetFlex>
          <ChartWidgetContainer>
          { sum.months && <ChartWidget
            heading="List of orders"
            content={<ListOfOrdersChart series={sum?.series} months={sum?.months} />}
          />}
          </ChartWidgetContainer>

          <CustomerActionsDivFlex>
            <CustomerActionsDiv style={{ height: "auto", marginTop: 5 }}>
              <InputContainer>
                <span>Track a delivery </span>
                <Input
                  type="text"
                  name="OrderNo"
                  onChange={(e) => handleInputChange(e)}
                  placeholder="Input waybill number"
                  // style={{textTransform:'uppercase'}}
                />
              </InputContainer>
              <FlexEnd>
                <Button
                  onClick={handleTrackOrder}
                  style={{ textTransform: "capitalize", fontSize: "small" }}
                  variant="outlined"
                >
                  TRACK
                </Button>
              </FlexEnd>
            </CustomerActionsDiv>

            <CustomerActionsDiv style={{ height: "auto", marginTop: 5 }}>
              <WalletBalances>
                <p>Current Balanace</p>
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  &#8358;{dollarUSLocale.format(wallet?.currentBalance)}
                </p>
              </WalletBalances>
              <WalletBalances>
                <p>Ledger Balanace</p>
                <p style={{ fontWeight: "bold", fontSize: "17px" }}>
                  &#8358;{dollarUSLocale.format(wallet?.ledgerBalance)}
                </p>
              </WalletBalances>
              <FlexEnd>
                <Link to="/wallet">
                  <Button variant="outlined">Top up card</Button>
                </Link>
              </FlexEnd>
            </CustomerActionsDiv>

            <CustomerActionsDiv style={{ height: "auto" }}>
              <Flex style={{ justifyContent: "space-evenly" }}>
                <Button onClick={handleOpenOrderType}>Create new order</Button>
                <Button
                  onClick={() => navigate("/dashboard/customer/vehicles-types")}
                >
                  Vehicles
                </Button>
              </Flex>
            </CustomerActionsDiv>
          </CustomerActionsDivFlex>
        </WidgetFlex>

        <VehiclesTableContainer>
          <Heading>Recent Orders</Heading>
          <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Scheduled Order" value={0} />
              <Tab label="Express Order" value={1} />
            </Tabs>
          </Box>
          <TabPanel value={0}>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Order Number</th>
                  <th>Recipient Name</th>
                  <th>Recipient Phone</th>
                  {/* <th>Est. Distance</th> */}
                  <th>Total Amount</th>
                  <th>Merchant Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              {
                !isLoading &&
              
              <tbody>
                {orderdata?.shipments
                  ?.map((row_, Key) => {
                    return (
                      <tr>
                        <td style={{width:'10px'}}>{new Date(row_.createdOn).toLocaleDateString()}</td>
                        <td>{row_.waybillNumber}</td>
                        
                        <td style={{width:'300px'}}>{row_.recipient.recipientName}</td>
                        <td style={{width:'300px'}}>{row_.recipient.phoneNumber}</td>
                        <td style={{width:'300px'}}>{"₦" + dollarUSLocale.format(row_.shipmentPayment.totalAmount)}</td>
                        <td style={{width:'300px'}}>{"₦" + dollarUSLocale.format(row_.shipmentPayment.cashonDelivery)}</td>
                        <td style={{width:'180px'}}>
                          {(row_?.status === 0 || row_?.status === 55 || row_?.status === 50) && <span style={{color:'#f9c851'}}>Pending</span>}
                          {row_?.status === 1 && <span style={{color:'#f9c851'}}>Picked</span>}
                          {row_?.status >= 2 && row_?.status < 8 && (row_?.status != 50 || row_?.status != 55) && <span style={{color:'#188ae2'}}>Arrived At Origin Station</span>}
                          {row_?.status >= 9 && row_?.status < 13 && (row_?.status != 50 || row_?.status != 55) && <span style={{color:'#188ae2'}}>Departed At Origin Station</span>}
                          {row_?.status >= 13 && row_?.status <= 15 && (row_?.status != 50 || row_?.status != 55) && <span style={{color:'#188ae2'}}>Arrived At Destination Station</span>}
                          {row_?.status > 15 &&  row_?.status < 20 && row_?.deliveryType == 'Hold for collection' && <span style={{color:'#f9c851'}}>Hold for collection</span>}
                          {row_?.status > 15 &&  row_?.status < 20 && row_?.deliveryType != 'Hold for collection' && <span style={{color:'#f9c851'}}>In Transit To Consignee</span>}
                          {row_?.status === 20 && <span style={{color:'#10c469'}}>Delivered</span>}
                          {row_?.status === 24 && <span style={{color:'#10c469'}}>Partially Delivered</span>}
                          {row_?.status === 99 && <span style={{color:'#10c469'}}>With Exception</span>}
                          {row_?.status === 52 && <span style={{color:'#f9c851'}}>Cancelled</span>}
                          {row_?.status === 100 && <span style={{color:'#000000'}}>Retured</span>}
                        </td>
                        <td style={{width:'180px'}}>
                          <Button
                            onClick={()=>navigate(
                              `/dashboard/customer/scheduled/${row_.waybillNumber}`
                            )}
                            style={{
                              textTransform: "capitalize",
                              fontSize: "small",
                            }}
                            variant="outlined"
                          >
                            Order details
                          </Button>
                          <Button
                            onClick={()=>officeCopy(row_.waybillNumber)}
                            style={{
                              textTransform: "capitalize",
                              fontSize: "small",
                            }}
                            variant="outlined"
                          >
                            Print
                          </Button>
                          {row_?.status  < 2 && <Button
                            onClick={()=>_cancelOrder(row_.waybillNumber)}
                            style={{
                              textTransform: "capitalize",
                              fontSize: "small",
                              color: "#BD1E1E",
                              borderColor: "#BD1E1E"
                            }}
                            className="btn-danger"
                            variant="outlined"
                          >
                            Cancel
                          </Button>}
                        </td>
                        {/* <td><button onClick={()=> alert(row_.vehicleNum)}>view</button></td> */}
                      </tr>
                    );
                  })}
              </tbody>
}
            </Table>
            {isLoading && <Button style={{width:'100%', marginTop:'20px'}}><CircularProgress/></Button>}
          </TableWrapper>
          </TabPanel>
          <TabPanel value={1}>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Order Number</th>
                  
                  <th>Pick-up</th>
                  <th>Destinations</th>

                  {/* <th>Est. Delivery Date</th> */}
                  <th>Order Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              {
                !isLoading &&
              
              <tbody>
                {orderdata.orders
                  ?.map((row_, Key) => {
                    return (
                      <tr>
                        <td style={{width:'10px'}}>{new Date(row_.orderDate).toLocaleDateString()}</td>
                        <td>{row_.orderNo}</td>
                        
                        <td style={{width:'300px'}}>{row_.pickup}</td>
                        <td style={{width:'300px'}}>{row_.destinationPoints.length + ' Destinations'}</td>

                        <td style={{width:'200px'}}>{row_.orderPrice === null ? "N/A" : row_?.status !== 10 ? "₦" + dollarUSLocale.format(row_?.orderPrice?.maxValue) : "₦" + dollarUSLocale.format(row_?.orderPrice?.actualValue) }</td>
                        <td style={{width:'180px'}}>
                          {row_?.status === 0 && <span style={{color:'#f9c851'}}>pending</span>}
                          {row_?.status === 1 && <span style={{color:'#188ae2'}}>accepted</span>}
                          {row_?.status === 2 && <span style={{color:'#ff6666'}}>awaiting pickup</span>}
                          {row_?.status === 3 && <span style={{color:'#666699'}}>arrived</span>}
                          {row_?.status === 4 && <span style={{color:'#f9c851'}}>awaiting rider code</span>}
                          {row_?.status === 5 && <span style={{color:'#bf4080'}}>In Transit</span>}
                          {row_?.status === 10 && <span style={{color:'#10c469'}}>Completed</span>}
                          {row_?.status === 11 && <span style={{color:'#ff5b5b'}}>cancelled</span>}
                        </td>
                        <td style={{width:'180px'}}>
                          <Link
                              className="unstyle-href"
                              to={`/track-order/${row_?.pickup}/${row_?.id}`}
                            >
                              <Button
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "small",
                                }}
                                variant="outlined"
                              >
                                Track Order
                              </Button>
                            </Link>
                        </td>
                        {/* <td><button onClick={()=> alert(row_.vehicleNum)}>view</button></td> */}
                      </tr>
                    );
                  })}
              </tbody>
}
            </Table>
            {isLoading && (
              <Button style={{ width: "100%", marginTop: "20px" }}>
                <CircularProgress />
              </Button>
            )}
          </TableWrapper>
          </TabPanel>
          </TabContext>
        </VehiclesTableContainer>
      </div>
    </>
  );
};

export default DashboardCustomer;
