import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../../components/navbar/navbar.component";
import { Table, TableWrapper } from "../../../components/Table/table.styled";
import { Heading } from "../../../components/Tags/tags.styled";
import { VehiclesTableContainer } from "../../Partner-Pages/Vehicle/vehicles.styled";
import {
  CustomerActionsDiv,
} from "../Dashboard/dashboard.styled";
import { walletPayments } from "../../../app/features/Customer/wallet/walletpaymentsSlice";
import { useDispatch, useSelector } from "react-redux";
import convertDate from "../../../helper/dateCoverter";
import { PaystackButton } from "react-paystack";
import { toast } from "react-toastify";
import { addPayment } from "../../../app/features/Customer/wallet/addWalletPayment";
import { addPaymentError } from "../../../app/features/Customer/wallet/addWalletPaymentErrorSlice";
import { getWallet } from "../../../app/features/Customer/wallet/getWalletSlice";
import { customerCards } from "../../../app/features/Customer/wallet/customercardsSlice";

// hatagas173@hostovz.com ----- paystackEMail

const paystack_public_key = "pk_live_a08339a8f51fd52f144e39bded503c3059aa66f9";
// const paystack_public_key = "pk_test_adeccd8e1f7b4b24716dfe32fa47b73e257b53b8";

const Wallet = () => {
  const userObj = JSON.parse(localStorage.getItem("user"));
  const currentUser = userObj?.extra;
  const userWalletObj = userObj?.extra.wallet;
  // const balanceIsHidden = JSON.parse(localStorage.getItem('hideBalance'))
  const [balanceIsHidden, setBalanceIsHidden] = useState(
    JSON.parse(localStorage.getItem("hideBalance"))
  );

  const navigate = useNavigate();

  const { isPaying } = useSelector((state) => state.addPayment);
  const { cards, isLoadingCards } = useSelector((state) => state.customercards);

  const [openTopUp, setOpenTopup] = useState(false);
  const [email, setEmail] = useState(userObj?.user?.email);
  const [amount, setAmount] = useState(null);
  const [payRef, setReference] = useState(null);
  const [card, setCard] = useState(null);
  const [openCardPay, setOpenCardPay] = useState(false);
  const [startDate, setStartDate] = useState("2024-01-09");
  const [endDate, setEndDate] = useState(
    new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      new Date().getDate().toString().padStart(2, "0")
  );

  const o = {
    startDate: startDate,
    endDate: endDate
  }

  const handlePaystackPayClick = () => {
    handlePaymentError();
    let componentProps = {
      email,
      amount: amount * 100,
      publicKey: paystack_public_key,
      text: "Pay Now",
      onSuccess: ({ reference }) => {
        if (reference) {
          submitPayment(reference);
        } else {
          handlePaymentError();
        }
      },
      onError: () => {
        handlePaymentError();
      },
      onClose: () => {
        toast.error("Payment gateway unexpectedly closed");
      },
    };
    let el = document.querySelector(".paystack_botton_");
    return el.click();
  };

  const dollarUSLocale = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  // const handlePaymentError = () => {
  //   const body = {
  //     walletId: userWalletObj.id,
  //     amount: parseFloat(amount),
  //     paymentDate: new Date(),
  //     customerCard: {
  //       customerId: currentUser.id,
  //       cardNumber: 'Temporary Card - ****',
  //       cardName: 'authorization.account_name'
  //     }
  //   }
  //   dispatch(addPaymentError(body))
  // }

  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "Redspeed - Wallet";
    dispatch(walletPayments(o));
    dispatch(customerCards());
    dispatch(getWallet());
  }, []);
  const { isLoading, data } = useSelector((state) => state.walletpayments);
  const { wallet } = useSelector((state) => state.getWallet);

  const handleOpenTopup = () => {
    setOpenTopup(true);
  };
  const handleCloseTopup = () => {
    setOpenTopup(false);
  };

  const handleHideBalance = () => {
    const balanceStatus = JSON.parse(localStorage.getItem("hideBalance"));
    if (balanceStatus === null || balanceStatus === false) {
      setBalanceIsHidden(true);
      localStorage.setItem("hideBalance", "true");
    } else {
      setBalanceIsHidden(false);
      localStorage.setItem("hideBalance", "false");
    }
  };
  const handlePaymentError = () => {
    const body = {
      walletId: userWalletObj.id,
      amount: parseFloat(amount),
      paymentDate: new Date(),
      createdAt: new Date(),
      createdBy: "Web",
    };
    dispatch(addPaymentError(body));
  };
  const handleOpenCardPay = () => {
    setOpenCardPay(true);
  };
  const handleCloseCardPay = () => {
    setOpenCardPay(false);
  };

  const componentProps = {
    email,
    amount: amount * 100,
    publicKey: paystack_public_key,
    metadata: {
      name: currentUser.firstName + currentUser.lastName,
      phone: currentUser.phoneNumber,
    },
    text: "Pay Now",
    onSuccess: ({ reference }) => {
      submitPayment(reference);
    },
    onClose: () => {
      toast.error("Payment gateway unexpectedly closed");
    },
  };

  const submitPayment = (reference) => {
    if (reference) {
      setReference(reference);
      console.log("reference", payRef);
      const body = {
        walletId: userWalletObj.id,
        amount: amount,
        reference: reference,
        paymentDate: new Date(),
        customerCard: {
          customerId: currentUser.id,
          cardNumber: "Temporary Card - ****",
          cardName: "authorization.account_name",
        },
      };
      dispatch(addPayment(body)).then((res) => {
        const { payload, error } = res;
        if (!error) {
          toast.success("Wallet topup is successful");
          handleCloseTopup();
          handleCloseCardPay();
          dispatch(walletPayments());
        } else {
          toast.error(payload);
        }
      });
    } else {
      toast.error("We are unable to process your request now");
    }
  };

  const handleCardPayment = () => {
    const body = {
      walletId: userWalletObj.id,
      amount: amount,
      paymentDate: new Date(),
      customerCardId: card,
      status: 0,
    };
    console.log("cardPayBody >>", body);
    dispatch(addPayment(body)).then((res) => {
      const { payload, error } = res;
      if (!error) {
        toast.success("Wallet topup is successful");
        handleCloseTopup();
        dispatch(walletPayments());
      } else {
        toast.error(payload);
      }
    });
  };

  const handleSearch = () => {
    dispatch(walletPayments(o));
  }

  return (
    <>
      <NavBar />
      <div className="main-content main-content-margin-top">
        {/* <CustomerActionsDiv style={{background:"rgba(103,45,42,1)"}}> */}
        {/* <CustomerActionsDiv style={{backgroundImage:"linear-gradient(to right, black, gray)",color:'white'}}> */}

        <CustomerActionsDiv
          style={{
            background: "rgb(34, 32, 32)",
            margin: "0 auto",
            marginBottom: "20px",
            // width: "98%",
          }}
        >
          <div className="py-3 px-4">
            <div class="card-body" style={{ color: "#FFFFFF" }}>
              <div className="d-flex justify-content-between">
                <div className="">
                  <span style={{ fontSize: "15px" }}>Current Balance</span>
                  {balanceIsHidden === true && (
                    <p style={{ fontWeight: "bold", fontSize: "28px" }}>
                      {" "}
                      &#8358;*********.**
                    </p>
                  )}

                  {(balanceIsHidden === false || balanceIsHidden === null) && (
                    <p style={{ fontWeight: "bold", fontSize: "28px" }}>
                      {" "}
                      &#8358;{dollarUSLocale.format(wallet?.currentBalance)}
                    </p>
                  )}
                </div>
                <div className="">
                  <Link
                    style={{
                      color: "#FFFFFF",
                      fontSize: "16px",
                      fontWeight: 500,
                      fontStyle: "italic",
                      textDecoration: "none",
                    }}
                    to="/wallet-payments"
                  >
                    {">>>"} Transaction History
                  </Link>
                </div>
              </div>
              <div className="">
                <span style={{ fontSize: "15px" }}>Ledger Balance</span>
                {balanceIsHidden === true && (
                  <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {" "}
                    &#8358;*********.**
                  </p>
                )}
                {(balanceIsHidden === false || balanceIsHidden === null) && (
                  <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {" "}
                    &#8358;{dollarUSLocale.format(wallet?.ledgerBalance)}
                  </p>
                )}
              </div>
            </div>
            <div className="border w-100 my-4"></div>
            <div className="d-flex align-items-center justify-content-between">
              <Box>
                <Button
                  onClick={() => handleOpenTopup()}
                  style={{
                    textTransform: "capitalize",
                    color: "white",
                  }}
                  variant="contained"
                >
                  Top up wallet
                </Button>
                {/* <Button
                onClick={()=>handleOpenTopup()}
                style={{
                  textTransform: "capitalize",
                  color: "white",
                }}
                variant="contained"
              >
                View Cards
              </Button> */}
              </Box>
              {balanceIsHidden === true && (
                <Button
                  style={{
                    color: "white",
                    textTransform: "capitalize",
                    fontSize: "small",
                  }}
                  variant="text"
                  onClick={() => handleHideBalance()}
                >
                  show balance <VisibilityOff style={{ marginLeft: "4px" }} />
                </Button>
              )}
              {(balanceIsHidden === false || balanceIsHidden === null) && (
                <Button
                  style={{
                    color: "white",
                    textTransform: "capitalize",
                    fontSize: "small",
                  }}
                  variant="text"
                  onClick={() => handleHideBalance()}
                >
                  hide balance <Visibility style={{ marginLeft: "4px" }} />
                </Button>
              )}
            </div>
          </div>
        </CustomerActionsDiv>

        {/* <div style={{ padding: "10px" }}>
          <Button
          onClick={()=>handleOpenTopup()}
            style={{
              background: "rgb(34, 32, 32)",
              textTransform: "capitalize",
              color: "white",
            }}
            variant="contained"
          >
            Top up wallet
          </Button>
        </div> */}

        <div name="Top Up Node">
          <Dialog open={openTopUp} onClose={handleCloseTopup}>
            <DialogTitle>Top up wallet</DialogTitle>
            <DialogContent>
              <div>
                <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Email address"
                    id="fullWidth"
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Amount"
                    id="fullWidth"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Box>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={() => handleCloseTopup()}
              >
                Close
              </Button>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                onClick={() => handleOpenCardPay()}
                disabled={email?.length && amount?.length > 0 ? false : true}
              >
                Pay now
              </Button>
            </DialogActions>
            <div style={{ display: "none" }}>
              <PaystackButton
                className="paystack_botton_"
                id="paystack_botton_"
                {...componentProps}
              />
            </div>
          </Dialog>
        </div>

        <div name="Select Card">
          <Dialog open={openCardPay} onClose={handleCloseCardPay}>
            <DialogTitle>Select prefered card below</DialogTitle>

            {isLoadingCards && (
              <Button
                style={{
                  textTransform: "capitalize",
                  fontWeight: "700",
                  border: "none",
                }}
                variant="outlined"
                color="primary"
              >
                <CircularProgress />
              </Button>
            )}
            {!isLoadingCards && (
              <DialogContent>
                <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  {cards.length > 0 ? (
                    <FormControl style={{ width: "100%", margin: "20px 0" }}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Payment option
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        //   value={assignDriverProperty.driver}
                        name="card"
                        onChange={(e) => setCard(e.target.value)}
                        //   onChange={handleAssignDriverChange}
                      >
                        {cards.filter(x => x.cardNumber != null).map((card, key) => {
                          return (
                            <MenuItem key={key} value={card.id}>
                              {"**** **** **** " +
                                card.cardNumber != null ? card.cardNumber.slice(-4) : '' +
                                " -- " +
                                card.bank}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    "No cards currently associated with this account"
                  )}
                </Box>

                <Button
                  variant="outlined"
                  onClick={() => handlePaystackPayClick()}
                >
                  Add new card
                </Button>
                <div style={{ display: "none" }}>
                  <PaystackButton
                    className="paystack_botton_"
                    id="paystack_botton_"
                    {...componentProps}
                  />
                </div>
              </DialogContent>
            )}
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseCardPay}
              >
                Close
              </Button>
              {isPaying ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="primary"
                >
                  <CircularProgress />
                </Button>
              ) : (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleCardPayment()}
                  disabled={card !== null ? false : true}
                >
                  Pay now
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>

        <VehiclesTableContainer>
          <Heading>Top-up history</Heading>
          <div className="d-flex flex-row-reverse">
              <div className="col-7">
                <div className="row">
                  <div className="col-5">
                    <FormControl fullWidth>
                      <TextField
                        id="date"
                        type="date"
                        defaultValue={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-5">
                    <FormControl fullWidth>
                      <TextField
                        id="date"
                        type="date"
                        defaultValue={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-2" style={{ display: "flex", flexDirection: "" }}>
                    <Button style={{ alignItems: "center"}} onClick={() => handleSearch()} size="large" color="primary" variant="outlined">
                      <SearchIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Bank name</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </thead>

              {!isLoading && (
                <tbody>
                  {data?.map((row_, Key) => {
                    return (
                      <tr>
                        <td>{Key + 1}</td>
                        <td>{row_.customerCard?.bank}</td>
                        {/* <td>{(row_.estimatedDistance)/1000}km</td> */}
                        <td>Completed</td>
                        <td>{convertDate(row_?.paymentDate)}</td>
                        <td>&#8358;{dollarUSLocale.format(row_?.amount)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
            {isLoading && (
              <Button style={{ width: "100%", marginTop: "20px" }}>
                <CircularProgress />
              </Button>
            )}
          </TableWrapper>
        </VehiclesTableContainer>
      </div>
    </>
  );
};

export default Wallet;
