import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import baseUrl from '../../../../helper/api';
import axios from "../../../../api/axios";

const initialState = {
    data:[],
    isLoading:null,
    status:null,
}

const token = userObj?.token

export const getAllOrders = createAsyncThunk(
    'partner/getAllOrders',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.get("/Order/all",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)

export const getOrderTops = createAsyncThunk(
    'partner/getOrderTops',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.get("/Order/ordertops",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)

export const getSummary = createAsyncThunk(
    'partner/getSummary',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.get("/Order/summary",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)

export const getExpOrders = createAsyncThunk(
    'partner/getExpOrders',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.get("/Order/express",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)

export const getSchOrders = createAsyncThunk(
    'partner/getSchOrders',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.get("/Order/scheduled",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const getAllOrdersSlice = createSlice({
    name: 'getAllOrders',
    initialState,
    reducers: {},
    extraReducers:{
        [getAllOrders.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [getAllOrders.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [getAllOrders.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})
export const getExpOrdersSlice = createSlice({
    name: 'getExpOrders',
    initialState,
    reducers: {},
    extraReducers:{
        [getExpOrders.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [getExpOrders.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [getExpOrders.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})
export const getSchOrdersSlice = createSlice({
    name: 'getSchOrders',
    initialState,
    reducers: {},
    extraReducers:{
        [getSchOrders.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [getSchOrders.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [getExpOrders.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})
export const getOrderTopsSlice = createSlice({
    name: 'getOrderTops',
    initialState,
    reducers: {},
    extraReducers:{
        [getOrderTops.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [getOrderTops.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [getOrderTops.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})
export const getSummarySlice = createSlice({
    name: 'getSummary',
    initialState,
    reducers: {},
    extraReducers:{
        [getSummary.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [getSummary.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [getSummary.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})
export default getAllOrdersSlice.reducer;