import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "./pages/Partner-Pages/Dashboard/dashboard.partner";
import PartnerVehiclePage from "./pages/Partner-Pages/Vehicle/vehicles.partner";
import PartnerOrderPage from "./pages/Partner-Pages/order/orders.partner";
import Login from "./pages/Auth/login";
import RegisterCustomer from "./pages/Auth/register.customer";
import RegisterPartner from "./pages/Auth/register.partner";
import PartnerDriversPage from "./pages/Partner-Pages/Driver/drivers.partner";
import EditProfile from "./pages/Partner-Pages/Profile/edit-profile.partner";
import ChangePassword from "./pages/Partner-Pages/Profile/change-password.partner";
import EditBankInfo from "./pages/Partner-Pages/Profile/edit-bank-info.partner";
import Finance from "./pages/Partner-Pages/Finance/finance.partner";
import DashboardCustomer from "./pages/Customer-Pages/Dashboard/dashboard.customer";
import CustomerOrderPage from "./pages/Customer-Pages/orders/orders.customer";
import PackageSchedulePage from "./pages/Customer-Pages/orders/package.schedule";
import MerchantReportPage from "./pages/Customer-Pages/orders/merchant.report";
import CustomerPickupPage from "./pages/Customer-Pages/pickup/pickup.customer";
import VehicleTypesCustomer from "./pages/Customer-Pages/Vehicle/vehicle_types.customer";
import TrackOrder from "./pages/Customer-Pages/track-order/track-order.customer";
import VerifyEmail from "./pages/Auth/verify-email";
import VerifyPhone from "./pages/Auth/verify-phone";
import ForgotPassword from "./pages/Auth/forget-password";
import ForgotPasswordSuccess from "./pages/Auth/forget-password-success";
import ConfirmPhone from "./pages/Auth/confirm-phone";
import ResetPassword from "./pages/Auth/UserAuth/change-password";
import DocumentUpload from "./pages/Auth/docUpload.partner";
import BankInformation from "./pages/Auth/bankInfo.partner";
import PartnerAccCreationCongrats from "./pages/Auth/congrats.partner";
import PrivateRoutes from "./helper/protectedRoutes";
import PartnerVerifyEmail from "./pages/Auth/verify-email.partner";
import { userObj } from "./helper/protectedRoutes";
import CustomerRoutes from "./helper/customerRoutes";
import PartnerRoutes from "./helper/partnerRoutes";
import AuthRoutes from "./helper/AuthRoutes";
import AddBulkVehicle from "./pages/Partner-Pages/Vehicle/bulkVehicle";
import Wallet from "./pages/Customer-Pages/wallet/wallet.customer";
import CustomerPayments from "./pages/Customer-Pages/wallet/walletcustomer.customer.jsx";
import DailyShipmentsPage from "./pages/Customer-Pages/Dss/daily.shipments"
import RCSPage from "./pages/Customer-Pages/Dss/dss.customer";
import RCSDetailPage from "./pages/Customer-Pages/Dss/dssDetail.customer";
import OrderTrackPage from "./pages/Customer-Pages/orders/track.customer.jsx";
import CODReportPage from "./pages/Customer-Pages/orders/cod.report.jsx";
import ScheduledWaybill from "./pages/Customer-Pages/orders/scheduled.waybill.jsx";
import ExpressWaybill from "./pages/Customer-Pages/orders/express.waybill.jsx";
import Scheduled from "./pages/Customer-Pages/orders/scheduled.track.jsx";
import UserKey from "./pages/Partner-Pages/Profile/user-key.customer.jsx";



const AppRoutes = () => {
  const isAuthenticated = userObj?.token;
  const userRole = userObj?.role[0];
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={"/login"} />} />
        <Route element={<AuthRoutes />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route element={<PartnerRoutes />}>
            <Route path="/dashboard/partner" element={<Dashboard />} />
            <Route path="/bulkvehicle" element={<AddBulkVehicle />} />
            <Route
              path="/dashboard/partner/vehicles"
              element={<PartnerVehiclePage />}
            />
            <Route
              path="/dashboard/partner/orders"
              element={<PartnerOrderPage />}
            />
            <Route
              path="/dashboard/partner/drivers"
              element={<PartnerDriversPage />}
            />
            <Route path="/dashboard/partner/finance" element={<Finance />} />
          </Route>
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route element={<CustomerRoutes />}>
            <Route path="/dashboard/customer" element={<DashboardCustomer />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/wallet-payments" element={<CustomerPayments />} />
            <Route
              path="/dashboard/customer/vehicles-types"
              element={<VehicleTypesCustomer />}
            />
            <Route
              path="/dashboard/customer/orders"
              element={<CustomerOrderPage />}
            />
            <Route
              path="/dashboard/customer/orders/package-schedule"
              element={<PackageSchedulePage />}
            />
            <Route
              path="/dashboard/customer/orders/merchant-report"
              element={<MerchantReportPage />}
            />
            <Route
              path="/dashboard/customer/daily-shipments"
              element={<DailyShipmentsPage />}
            />
            <Route
              path="/dashboard/customer/rcs"
              element={<RCSPage />}
            />
            <Route
              path="/dashboard/customer/rcs/:id"
              element={<RCSDetailPage />}
            />
            <Route
              path="/dashboard/customer/order-tracking/:trackNo"
              element={<OrderTrackPage />}
            />
            <Route
              path="/dashboard/customer/cashondelivery"
              element={<CODReportPage />}
            />
            <Route
              path="/dashboard/customer/waybill/:id"
              element={<ScheduledWaybill />}
            />
            <Route
              path="/dashboard/customer/expwaybill/:id"
              element={<ExpressWaybill />}
            />
            <Route
              path="/dashboard/customer/scheduled/:id"
              element={<Scheduled />}
            />
          </Route>
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard/edit-profile" element={<EditProfile />} />
          <Route path="/dashboard/user-api-keys" element={<UserKey />} />
          <Route
            path="/dashboard/edit-bank-information"
            element={<EditBankInfo />}
          />
          <Route
            path="/dashboard/change-password"
            element={<ChangePassword />}
          />
        </Route>
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/register/partner" element={<RegisterPartner />} />
        <Route
          path="/register/partner/verify-email"
          element={<PartnerVerifyEmail />}
        />
        <Route path="/register/customer" element={<RegisterCustomer />} />
        <Route path="/register/customer" element={<RegisterCustomer />} />
        <Route path="/track-order/:pickup/:orderid" element={<TrackOrder />} />
        <Route
          path="/congratulation"
          element={<PartnerAccCreationCongrats />}
        />
        <Route path="/bank-information" element={<BankInformation />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/verify-phone" element={<VerifyPhone />} />
        <Route path="/confirm-mobile-number" element={<ConfirmPhone />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/forgot-password/success"
          element={<ForgotPasswordSuccess />}
        />
        <Route path="/document-upload" element={<DocumentUpload />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
