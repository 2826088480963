import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../../../app/features/Customer/Order/getOrderSlice";
import logo from "../../../assets/images/rse-logo.png";
import { Waybill } from "./waybill.styled";
import Barcode from "react-barcode";

const ScheduledWaybill = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const dollarUSLocale = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const [data, setData] = React.useState({});

  useEffect(() => {
    dispatch(getOrder(id)).then((res) => {
      const { error, payload } = res;
      if (!error) {
        setData(payload);
      }
    });
  }, []);
  const officeCopy =() => {
    setTimeout(() => window.print(), 300)
  }
  const paymentDefinition = (type) => {
    switch (type) {
      case 0:
        return "Prepaid";
      case 1:
        return "POD";
      default:
        return "Prepaid";
    }
  };

  return (
    <>
    <div className="no-print row">
      <a className="btn btn-primary btn-sm" onClick={() => officeCopy()}>Print Copy</a>
    </div>
      <Waybill className="container waybill">
        {data.shipment != null &&
        <div className="x-waybill">
        <div className="row">
          <div className="col-6 col-sm-6 col-md-6">
            <div className="row">
              <div className="col-12">
                <img
                  src={logo}
                  style={{width: 300, height: 40}}
                />
              </div>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-6">
            <div style={{float: 'right'}}>
            <Barcode
                    height={20}
                    value={data.orderNo}
                    options={{ format: "CODEE39", width: 1, height: 2 }}
                    renderer="img"
                    displayValue={true}
                  />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <span style={{fontSize: 13, fontWeight: 'bold'}}
              >DOMESTIC AIRWAY BILL</span
            ><br />
            <span style={{fontSize: 13, fontWeight: 'bold'}}
              >NON-NEGOTIABLE</span
            >
          </div>
        </div>
        <div className="row">
          <div className="col-5 col-sm-5 col-md-5 p-0">
            <vr />
            <fieldset
              className="fieldset-nobottom category"
              style={{minHeight: 150}}
            >
              <legend style={{fontsSize: 15}}>SENDER</legend>
              <dl className="cat-div mb-0">
                <div className="row">
                  <dt className="col-4">Acct/Card No:</dt>
                  <dd className="col-8">{ data.shipment.senderAccountNo }</dd>
                </div>
                <div className="row">
                  <dt className="col-4">Acct Name:</dt>
                  <dd className="col-8">
                    <span v-if="!shipment.senderName">&nbsp;&nbsp;</span>
                    <span v-else>{ data.shipment.companyName }</span>
                  </dd>
                </div>
                <div className="row">
                  <dt className="col-4">Address:</dt>
                  <dd className="col-8">
                    <span v-if="!shipment.senderAddress">N/A</span>
                    <span v-else>{ data.shipment.senderAddress }</span>
                  </dd>
                </div>
                <div className="row">
                  <dt className="col-4">Sender Name:</dt>
                  <dd className="col-8">{ data.shipment.senderName }</dd>
                </div>
                <div className="row">
                  <dt className="col-4">Phone:</dt>
                  <dd className="col-8">{ data.shipment.senderPhone }</dd>
                </div>
                <div className="row">
                  <dt className="col-4">Origin:</dt>
                  <dd className="col-8">
                    <b>{ data.shipment.origin.abbr }</b>
                  </dd>
                </div>
              </dl>
            </fieldset>
            <fieldset className="fieldset category" style={{minHeight: 150}}>
              <legend style={{fontSize: 15}}>RECIPIENT</legend>
              <div className="cat-div">
                <div className="row">
                  <dt className="col-4">Name:</dt>
                  <dd className="col-8">{ data.shipment.recipient.recipientName.substring(0, 26) }</dd>
                </div>
                <div className="row">
                  <dt className="col-4">Address:</dt>
                  <dd className="col-8" v-if="shipment">
                    { data.shipment.recipient.streetAddress }
                  </dd>
                </div>
                { data.shipment.town && <div className="row">
                  <dt className="mt-2 col-4">
                    Delivery Town:
                  </dt>
                  <dd className="mt-2 col-8">
                    { data.shipment.town.abbr }
                  </dd>
                </div>}
                <div className="row">
                  <dt className="col-4">Phone:</dt>
                  <dd className="col-8">{ data.shipment.recipient.phoneNumber }</dd>
                </div>
                <div className="row">
                  <dt className="mt-2 col-4">Destination:</dt>
                  <dd className="mt-2 col-8">
                    <b>{ data.shipment.city.abbr }</b>
                  </dd>
                </div>
              </div>
            </fieldset>
            <vr />
          </div>
          <div className="col-7 col-sm-7 col-md-7 p-0">
            <vr />
            <div className="row m-0 h-100">
              <div className="col-6 col-sm-6 col-md-6 p-0">
                <fieldset
                  className="fieldset h-100 category"
                  style={{minHeight: 300 }}
                >
                  <legend style={{fontSize: 15}}>SHIPMENT DETAIL</legend>
                  <dl className="cat-div">
                    <div className="row">
                      <dt className="col-6">Pieces:</dt>
                      <dd className="col-6">{ data.shipment.unit }</dd>
                    </div>
                    <div className="row">
                      <dt className="col-6">Weight:</dt>
                      <dd className="col-6">
                        { data.shipment.weight } <span>kg</span>
                      </dd>
                    </div>
                    <div className="row">
                      <dt className="col-6">Packaging:</dt>
                      <dd className="col-6">
                        <span v-else>{ data.shipment.packaging }</span>
                      </dd>
                    </div>
                    <div className="row">
                      <dt className="col-6">Declared Value:</dt>
                      <dd className="col-6">
                        {
                          Number(
                            data.shipment.shipmentPayment.declaredValue
                          ).toLocaleString()
                        }
                      </dd>
                    </div>
                    <div className="row">
                      <dt className="col-6">Delivery Instr:</dt>
                      <dd className="col-6">&nbsp;&nbsp;</dd>
                    </div>
                    <div className="row">
                      <dt className="col-6">Order/Flyer No:</dt>
                      <dd className="col-6">
                      { !data.shipment.flyerNo && <span v-if="!shipment.flyerNo">&nbsp;&nbsp;</span> }
                       { data.shipment.flyerNo &&  <span v-else>{ data.shipment.flyerNo }</span>}
                      </dd>
                    </div>
                    <div className="row">
                      <dt className="col-6">Content Desc:</dt>
                      <dd className="col-6">
                        { !data.shipment.contentDescription && <span
                          >&nbsp;&nbsp;</span
                        >}
                        { data.shipment.contentDescription && <span>{ data.shipment.contentDescription }</span>}
                      </dd>
                    </div>
                    &nbsp;
                    <div className="row">
                      <dt className="col-6">Date:</dt>
                      <dd className="col-6">
                        {
                          data.shipment.createdOn &&
                          new Date(data.shipment.createdOn).toLocaleDateString()
                        }
                      </dd>
                    </div>
                    <div className="row">
                      <dt className="col-6">OPS:</dt>
                      <dd className="col-6">
                        { data.shipment.boxandCrating != 'OPS' && <span>No</span> }
                        { data.shipment.boxandCrating == 'OPS' && <span>Yes</span> }
                      </dd>
                    </div>
                    <div className="row">
                      <dt className="col-6">OPS Detail:</dt>
                      <dd className="col-6">&nbsp;&nbsp;</dd>
                    </div>
                  </dl>
                </fieldset>
                <vr />
              </div>
              <div className="col-6 col-sm-6 col-md-6 p-0">
                <vr />
              <fieldset className="fieldset h-100 category">
                <dl className="cat-div" style={{marginBottom: 0}}>
                <div className="row">
                  <dt className="col-6">Item Cost:</dt>
                  <dd className="col-6">{ data.shipment.shipmentPayment.paymentMethod === 'Credit' ? 'A/C' : Number(data.shipment.shipmentPayment.declaredValue).toLocaleString()}</dd>
                  </div>
                  <div className="row">
                  <dt className="col-6">Insurance:</dt>
                  <dd className="col-6">{data.shipment.shipmentPayment.paymentMethod === 'Credit' ? 'A/C' : Number(data.shipment.shipmentPayment.insuranceValue).toLocaleString()}</dd>
                  </div>
                  <div className="row">
                  <dt className="col-6">Shipping:</dt>
                  <dd className="col-6">{ data.shipment.shipmentPayment.paymentMethod === 'Credit' ? 'A/C' : Number(data.shipment.shipmentPayment.servicePrice + data.shipment.shipmentPayment.onforwardingCharge).toLocaleString()}</dd>
                  </div>
                  <div className="row">
                  <dt className="col-6">VAT:</dt>
                  <dd className="col-6">{ data.shipment.shipmentPayment.paymentMethod === 'Credit' ? 'A/C' : Number(data.shipment.shipmentPayment.vat).toLocaleString()}</dd>
                  </div>
                  <div className="row">
                  <dt className="col-6">Total Amount:</dt>
                  <dd className="col-6">{ data.shipment.shipmentPayment.paymentMethod === 'Credit' ? 'A/C' : Number(data.shipment.shipmentPayment.onforwardingCharge + data.shipment.shipmentPayment.insuranceValue + data.shipment.shipmentPayment.servicePrice + data.shipment.shipmentPayment.vat).toLocaleString() }</dd>
                  </div>
                  { data.shipment.shipmentPayment.paymentMethod != 'Credit' && <div className="row">
                  <dt className="col-6">Payment Type:</dt>
                  <dd className="col-6">{ data.shipment.shipmentPayment.paymentMethod === 'Cash' || data.shipment.shipmentPayment.paymentMethod === 'Web' ? 'Pay on Delivery' : 'Prepaid' }</dd>
                  </div>}
                </dl>
                <div style={{paddingTop: 15, textAlign: 'center'}}>
                  <h4>{ (data.shipment.shipmentPayment.paymentMethod === 'Wallet' && data.shipment.shipmentPayment.cashonDelivery == 0) ? 'NO PAYMENT' : 'COLLECT '+ Number(data.shipment.shipmentPayment.paymentMethod === 'Wallet' ? data.shipment.shipmentPayment.cashonDelivery : data.shipment.shipmentPayment.totalAmount + data.shipment.shipmentPayment.cashonDelivery).toLocaleString() }</h4>
                </div>
              </fieldset>
              </div>
            </div>
            <vr />
          </div>
        </div>
      </div>
       }
        
      </Waybill>
    </>
  );
};

export default ScheduledWaybill;
